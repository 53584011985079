
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiBase, ApiMerchant, ApiCategories } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import _ from "lodash";
import { MBTaggings, ELDatatable } from "magicbean-saas-common";
import AddPriceRuleModal from "@/views/merchants/addPriceRuleModal.vue";
import { useI18n } from "vue-i18n";
import { setModuleBCN } from "@/core/directive/function/common";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";

interface TaggingValue {
  short_key: string;
  items: Array<TaggingValueItem>;
}

interface TaggingValueItem {
  short_key: string;
  value: string | Array<string>;
}

interface Categories {
  id: number;
  name: string;
  code: number;
  status: number;
  category_items: Array<any>;
  parent_id: number;
}
export default defineComponent({
  name: "merchants-distribution",
  components: {
    MBTaggings,
    ELDatatable,
    AddPriceRuleModal,
    PermissionCommon,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const basicSegmentationSource = ref([]);
    const basicSegmentationValues = ref([]);
    const distributionToChinaSource = ref([]);
    const distributionToChinaValues = ref([]);
    const distributionToHKSource = ref([]);
    const distributionToHKValues = ref([]);
    const B2BResellerSource = ref([]);
    const B2BResellerValues = ref([]);
    const affiliateProgramSource = ref([]);
    const affiliateProgramValues = ref([]);
    const membershipProgramSource = ref([]);
    const membershipProgramValues = ref([]);
    const travelRetailProgramSource = ref([]);
    const travelRetailProgramValues = ref([]);
    const distributionPartnersSource = ref([]);
    const distributionPartnersValues = ref([]);
    const selectData = ref<Array<Categories>>([]);

    const ruleId = ref(0);

    const options = ref({
      ruleLoading: true,
      ruleCurrentPage: 1,
      ruleTotal: 0,
      ruleTotalPage: 0,
      rulePageSize: 10,
      ruleList: [],
      ruleColumns: [
        {
          label: t("merchants.distributionMode"),
          prop: "distributionMode",
          slot: true,
        },
        {
          label: t("merchants.salesChannel"),
          prop: "__show.retail_channel_id",
        },
        {
          label: t("merchants.brand"),
          prop: "brand",
          slot: true,
        },
        {
          label: t("merchants.category"),
          prop: "category",
          slot: true,
        },
        {
          label: t("merchants.vatDeductible"),
          prop: "vat_deductible",
          slot: true,
        },
        {
          label: t("merchants.commission"),
          prop: "commission",
          slot: true,
          align: "right",
        },
        {
          label: t("merchants.discount"),
          prop: "discount",
          slot: true,
          align: "right",
        },
        {
          label: t("merchants.distributionMargin"),
          prop: "distribution_margin",
          slot: true,
          align: "right",
        },
        {
          label: t("merchants.basedOn"),
          prop: "basedOn",
          slot: true,
        },
        {
          label: t("merchants.priceBaseline"),
          prop: "priceBaseline",
          slot: true,
        },
        {
          label: t("merchants.minAmount"),
          prop: "min_amount",
          align: "right",
        },
        {
          label: t("merchants.maxAmount"),
          prop: "max_amount",
          align: "right",
        },
        {
          label: t("common.action"),
          prop: "actions",
          width: 100,
          align: "right",
          slot: true,
        },
      ],
    });

    const formData = ref({
      merchant_id: route.params.id,
      service_status: 0,
      retail_mode_to_china: 0,
      wholesale_mode_to_china: 0,
      retail_mode_to_hk_macau: 0,
      wholesale_mode_to_hk_macau: 0,
      d2b_reseller: "",
      affiliate_program: "",
      membership_program: "",
      travel_retail_program: "",
    });

    const short_key = [
      "merchant_dist_spec_basic_segmentation",
      "merchant_dist_spec_distribution_to_china",
      "merchant_dist_spec_distribution_to_hk_macau",
      "merchant_dist_spec_b2b_reseller",
      "merchant_dist_spec_affiliate_program",
      "merchant_dist_spec_membership_program",
      "merchant_dist_spec_travel_retail_program",
      "merchant_dist_spec_distribution_partners",
    ];

    const distributionTaggingsRef = ref<null | HTMLFormElement>(null);
    const distributionToChina = ref<null | HTMLFormElement>(null);
    const distributionToHK = ref<null | HTMLFormElement>(null);
    const B2BReseller = ref<null | HTMLFormElement>(null);
    const affiliateProgram = ref<null | HTMLFormElement>(null);
    const membershipProgram = ref<null | HTMLFormElement>(null);
    const travelRetailProgram = ref<null | HTMLFormElement>(null);
    const distributionPartners = ref<null | HTMLFormElement>(null);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const getTaggingDataSource = () => {
      return ApiBase.getTaggingGroupData({ short_key: short_key })
        .then(({ data }) => {
          if (data.code == 0) {
            basicSegmentationSource.value =
              data.data.merchant_dist_spec_basic_segmentation?.items || [];
            distributionToChinaSource.value =
              data.data.merchant_dist_spec_distribution_to_china?.items || [];
            distributionToHKSource.value =
              data.data.merchant_dist_spec_distribution_to_hk_macau?.items ||
              [];
            B2BResellerSource.value =
              data.data.merchant_dist_spec_b2b_reseller?.items || [];
            affiliateProgramSource.value =
              data.data.merchant_dist_spec_affiliate_program?.items || [];
            membershipProgramSource.value =
              data.data.merchant_dist_spec_membership_program?.items || [];
            travelRetailProgramSource.value =
              data.data.merchant_dist_spec_travel_retail_program?.items || [];
            distributionPartnersSource.value =
              data.data.merchant_dist_spec_distribution_partners?.items || [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const getTaggingDataValues = () => {
      return ApiMerchant.getMerchantDistributionData({
        merchant_id: route.params.id,
      })
        .then(({ data }) => {
          if (data.code == 0) {
            Object.keys(formData.value).forEach((key) => {
              formData.value[key] = data.data[key];
            });
            basicSegmentationValues.value =
              data.data.tagging_data.merchant_dist_spec_basic_segmentation
                ?.items || [];
            distributionToChinaValues.value =
              data.data.tagging_data.merchant_dist_spec_distribution_to_china
                ?.items || [];
            distributionToHKValues.value =
              data.data.tagging_data.merchant_dist_spec_distribution_to_hk_macau
                ?.items || [];
            B2BResellerValues.value =
              data.data.tagging_data.merchant_dist_spec_b2b_reseller?.items ||
              [];
            affiliateProgramValues.value =
              data.data.tagging_data.merchant_dist_spec_affiliate_program
                ?.items || [];
            membershipProgramValues.value =
              data.data.tagging_data.merchant_dist_spec_membership_program
                ?.items || [];
            travelRetailProgramValues.value =
              data.data.tagging_data.merchant_dist_spec_travel_retail_program
                ?.items || [];
            distributionPartnersValues.value =
              data.data.tagging_data.merchant_dist_spec_distribution_partners
                ?.items || [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const getCategoriesList = () => {
      ApiCategories.getCategorySourceData({})
        .then(({ data }) => {
          if (data.code == 0) {
            selectData.value.splice(0, selectData.value.length, ...data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getRuleList = () => {
      options.value.ruleLoading = true;
      return ApiMerchant.getMerchantDistributionPriceRuleList({
        merchant_id: route.params.id,
        page: options.value.ruleCurrentPage,
        page_size: options.value.rulePageSize,
      })
        .then(({ data }) => {
          options.value.ruleLoading = false;
          if (data.code == 0) {
            options.value.ruleList = data.data.items;
            options.value.ruleTotal = data.data.total;
            options.value.ruleTotalPage = data.data.total_page;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getUpdateRuleList = () => {
      options.value.ruleCurrentPage = 1;
      getRuleList();
    };

    const setRulePerPage = (val) => {
      options.value.rulePageSize = val;
      getRuleList();
    };

    const handleRuleCurrentChange = (val: number) => {
      options.value.ruleCurrentPage = val;
      getRuleList();
    };

    const handleAddRule = () => {
      ruleId.value = 0;
    };

    const handleEditRule = (index, row) => {
      ruleId.value = row.id;
    };

    const handleDeleteRule = (index, row) => {
      Swal.fire({
        text: "Are you sure you would like to delete it?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } = await ApiMerchant.deletePriceRule({
            id: row.id,
          });
          loading.value = false;
          if (data.code == 0) {
            Swal.fire({
              text: "Deleted successfully!",
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              getRuleList();
            });
          }
        }
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          let taggingArr: Array<TaggingValue> = [];

          let basicData = distributionTaggingsRef.value?.formData;
          let basicitems: Array<TaggingValueItem> = [];
          for (let item in basicData) {
            basicitems.push({
              short_key: item,
              value: basicData[item],
            });
          }
          let basicObj: TaggingValue = {
            short_key: "merchant_dist_spec_basic_segmentation",
            items: basicitems,
          };
          taggingArr.push(basicObj);

          let data = distributionToChina.value?.formData;
          let items: Array<TaggingValueItem> = [];
          for (let item in data) {
            items.push({
              short_key: item,
              value: data[item],
            });
          }
          let obj: TaggingValue = {
            short_key: "merchant_dist_spec_distribution_to_china",
            items: items,
          };
          taggingArr.push(obj);

          let data1 = distributionToHK.value?.formData;
          let items1: Array<TaggingValueItem> = [];
          for (let item in data1) {
            items1.push({
              short_key: item,
              value: data1[item],
            });
          }
          let obj1: TaggingValue = {
            short_key: "merchant_dist_spec_distribution_to_hk_macau",
            items: items1,
          };
          taggingArr.push(obj1);

          let data2 = B2BReseller.value?.formData;
          let items2: Array<TaggingValueItem> = [];
          for (let item in data2) {
            items2.push({
              short_key: item,
              value: data2[item],
            });
          }
          let obj2: TaggingValue = {
            short_key: "merchant_dist_spec_b2b_reseller",
            items: items2,
          };
          taggingArr.push(obj2);

          let data3 = affiliateProgram.value?.formData;
          let items3: Array<TaggingValueItem> = [];
          for (let item in data3) {
            items3.push({
              short_key: item,
              value: data3[item],
            });
          }
          let obj3: TaggingValue = {
            short_key: "merchant_dist_spec_affiliate_program",
            items: items3,
          };
          taggingArr.push(obj3);

          let data4 = membershipProgram.value?.formData;
          let items4: Array<TaggingValueItem> = [];
          for (let item in data4) {
            items4.push({
              short_key: item,
              value: data4[item],
            });
          }
          let obj4: TaggingValue = {
            short_key: "merchant_dist_spec_membership_program",
            items: items4,
          };
          taggingArr.push(obj4);

          let data5 = travelRetailProgram.value?.formData;
          let items5: Array<TaggingValueItem> = [];
          for (let item in data5) {
            items5.push({
              short_key: item,
              value: data5[item],
            });
          }
          let obj5: TaggingValue = {
            short_key: "merchant_dist_spec_travel_retail_program",
            items: items5,
          };
          taggingArr.push(obj5);

          let data6 = distributionPartners.value?.formData;
          let items6: Array<TaggingValueItem> = [];
          for (let item in data6) {
            items6.push({
              short_key: item,
              value: data6[item],
            });
          }
          let obj6: TaggingValue = {
            short_key: "merchant_dist_spec_distribution_partners",
            items: items6,
          };
          taggingArr.push(obj6);

          loading.value = true;
          if (submitButton.value) {
            // submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiMerchant.updateMerchantDistributionData({
            ...formData.value,
            tagging_data: taggingArr,
          })
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const backToList = () => {
      if (route.path.indexOf("offline-merchants") != -1) {
        router.push("/merchant-network/offline-merchants");
      } else {
        router.push("/merchants");
      }
    };

    const init = () => {
      loading.value = true;
      Promise.all([
        getTaggingDataSource(),
        getTaggingDataValues(),
        getUpdateRuleList(),
        getCategoriesList(),
      ])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      init();
    });
    return {
      t,
      options,
      loading,
      formRef,
      selectData,
      submitButton,
      distributionTaggingsRef,
      basicSegmentationSource,
      basicSegmentationValues,
      distributionToChinaSource,
      distributionToChinaValues,
      distributionToHKSource,
      distributionToHKValues,
      B2BResellerSource,
      B2BResellerValues,
      affiliateProgramSource,
      affiliateProgramValues,
      membershipProgramSource,
      membershipProgramValues,
      travelRetailProgramSource,
      travelRetailProgramValues,
      distributionPartnersSource,
      distributionPartnersValues,
      distributionToChina,
      distributionToHK,
      B2BReseller,
      affiliateProgram,
      travelRetailProgram,
      distributionPartners,
      membershipProgram,
      formData,
      ruleId,
      getRuleList,
      handleAddRule,
      handleEditRule,
      handleDeleteRule,
      submit,
      backToList,
      setRulePerPage,
      handleRuleCurrentChange,
    };
  },
});
