
import { defineComponent, ref, watch, onMounted, computed } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { ApiMerchant, ApiBrands } from "@/core/api";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";

interface Brands {
  id: number;
  logo: string;
  name: string;
  status: number;
  description: string;
}
interface propData {
  checkStrictly: boolean;
  value: string;
  label: string;
  children: string;
  lazy: boolean;
  lazyLoad: any;
}
export default defineComponent({
  name: "add-price-rule-modal",
  props: {
    id: { type: Number, default: 0 },
    merchantId: { type: Number, default: 0 },
    tableData: {
      type: Array,
      required: true,
    },
  },
  components: {},
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const selectData = ref([]);
    const formRef = ref<null | HTMLFormElement>(null);
    const addPriceRuleModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);
    const brandLoading = ref<boolean>(false);
    const brands = ref<Array<Brands>>([]);
    const tableArr = ref(props.tableData);
    const propData = ref<propData>({
      checkStrictly: true,
      value: "id",
      label: "name",
      children: "category_items",
      lazy: false,
      lazyLoad(node, resolve) {
        resolve(node.children);
      },
    });
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: props.id,
      brand_id: "",
      category_id: "",
      service_mode: "",
      retail_channel_id: "",
      commission: 0,
      discount: 0,
      vat_deductible: 0,
      distribution_margin: 0,
      based_on: "",
      price_baseline: "",
      min_amount: 0,
      max_amount: 0,
    });
    const addData = ref({
      merchant_id: props.merchantId,
      brand_id: "",
      category_id: "",
      service_mode: "",
      commission: 0,
      discount: 0,
      vat_deductible: 0,
      retail_channel_id: "",
      // distribution_margin: 0,
      based_on: "",
      price_baseline: "",
      min_amount: 0,
      max_amount: 0,
    });

    watch(
      () => props.id,
      (newVal) => {
        if (newVal) {
          getFormData(newVal);
        } else {
          resetForm();
        }
      }
    );

    const rules = ref({
      // category_id: [
      //   {
      //     required: true,
      //     message: "Category is required",
      //     trigger: "change",
      //   },
      // ],
      service_mode: [
        {
          required: true,
          message: "Service Mode is required",
          trigger: "change",
        },
      ],
      retail_channel_id: [
        {
          required: true,
          message: "Retail Channel is required",
          trigger: "change",
        },
      ],
      commission: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      discount: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      // distribution_margin: [
      //   {
      //     required: true,
      //     message: "Distribution Margin is required",
      //     trigger: "change",
      //   },
      // ],
      based_on: [
        {
          required: true,
          message: "Based on is required",
          trigger: "change",
        },
      ],
      price_baseline: [
        {
          required: true,
          message: "Price Baseline is required",
          trigger: "change",
        },
      ],
      min_amount: [
        {
          required: true,
          message: "Min. Amount is required",
          trigger: "change",
        },
      ],
      max_amount: [
        {
          required: true,
          message: "Max. Amount is required",
          trigger: "change",
        },
      ],
    });

    const getdistributionMargin = computed(() => {
      console.log(
        Number(formData.value.discount) + Number(formData.value.commission)
      );
      return (
        Number(formData.value.discount) + Number(formData.value.commission)
      );
    });

    const getBrandsList = (search?: string, id?: number) => {
      brandLoading.value = true;
      ApiBrands.getBrandSourceData({
        search_value: search,
        id: id,
      })
        .then(({ data }) => {
          brandLoading.value = false;
          if (data.code == 0) {
            brands.value.splice(0, brands.value.length, ...data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const debounceBrandSearch = _.debounce(getBrandsList, 1000);
    const searchBrandItems = (query) => {
      debounceBrandSearch(query);
    };
    const parentChange = (value) => {
      if (value) {
        formData.value.category_id = value[value.length - 1];
      } else {
        formData.value.category_id = "";
      }
    };

    const getCompanyChanelList = () => {
      return ApiMerchant.channelDataSource({
        merchant_id: props.merchantId,
        status: 10,
        max_item: "all",
      })
        .then(({ data }) => {
          if (data.code == 0) {
            selectData.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const getFormData = (id) => {
      if (id) {
        loading.value = true;
        ApiMerchant.getMerchantDistributionPriceRuleData({ id: id })
          .then(({ data }) => {
            loading.value = false;
            if (data.code == 0) {
              Object.keys(formData.value).forEach((key) => {
                formData.value[key] = data.data[key];
              });
              getBrandsList("", data.data.brand_id);
              // getBrandsList("", 3180);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          if (props.id) {
            editKeyarameters();
          } else {
            addKeyarameters();
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const addKeyarameters = () => {
      Object.keys(addData.value).forEach((key) => {
        addData.value[key] = formData.value[key] || addData.value[key];
      });
      ApiMerchant.addMerchantDistributionPriceRule(addData.value)
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              resetForm();
              hideModal(addPriceRuleModalRef.value);
              emit("update-list");
            });
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const editKeyarameters = () => {
      ApiMerchant.updatePriceRule(formData.value)
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              resetForm();
              hideModal(addPriceRuleModalRef.value);
              emit("update-list");
            });
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleDiscard = () => {
      hideModal(addPriceRuleModalRef.value);
      resetForm();
      emit("reset-form");
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onMounted(() => {
      getCompanyChanelList();
      modalHideListener(addPriceRuleModalRef.value, () => {
        emit("reset-form");
      });
    });

    return {
      t,
      loading,
      selectData,
      formData,
      rules,
      formRef,
      propData,
      tableArr,
      brands,
      addPriceRuleModalRef,
      submitButton,
      getdistributionMargin,
      parentChange,
      searchBrandItems,
      getFormData,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
